/* All styles required for UI to work in an app */
@forward './style/roboto-flex.css';
@forward './style/overlay-prebuilt.css';
@forward './style/a11y-prebuilt.css';
@forward './style/deeppurple-amber.css';
@forward './style/index';
@forward './style/directives/index';

.ui-body-grabbing {
    cursor: grabbing !important;
    user-select: none;
}
