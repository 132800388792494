@use '../minimal/layout';

$input-height: 3.4rem;

:root:not([data-uinew]) {
    [ui-theme*='small'] {
        --default-height: 3.4rem;
        --ui-height: 3.4rem;

        .input {
            --height: var(--ui-height);

            .input-icon {
                --margin-top: -2.5rem;
            }
        }

        .ui-notification {
            --padding: 0.9rem 3.6rem 0.9rem 1rem;
            --width: 28rem;
        }

        .selectable {
            --height: var(--ui-height);
        }
    }
}
