@use "sass:map";

$ui-color: (
    'black-off': #343434,
    'black-off-dark': #111111,
    'blue-dark': #1558a8,
    'blue-light': #edf5ff,
    'blue-semilight': #03aaff,
    'blue-superlight': #f3f9ff,
    'gold-light': #fffdf4,
    'gold': #de9516,
    'green': #2cbc45,
    'grey-dark': #9b9b9b,
    'grey-semidark': #b5b5b5,
    'grey-semilight': #efefef,
    'grey-light': #fbfbfb,
    'grey': #d6d6d6,
    'orange': #f5a623,
    'red-light': #fbe6e6,
    'red': #c34747,
    'white-off': #f7f7f7,
    'white-off-light': #fbfbfb,
    'white': #ffffff,
    'black': #000000,
    'black-off-semi': #1f1f1f,
    'grey-6': #0f0f0f,
    'grey-12': #1f1f1f,
    'grey-21': #363636,
    'grey-31': #4f4f4f,
    'grey-61': #9b9b9b,
    'grey-71': #b5b5b5,
    'grey-84': #d6d6d6,
    'grey-86': #dbdbdb,
    'grey-89': #e2e2e2,
    'grey-92': #ebebeb,
    'grey-93': #eeeeee,
    'grey-94': #f0f0f0,
    'grey-95': #f3f3f3,
    'grey-96': #f5f5f5,
    'grey-97': #f8f8f8,
    'grey-98': #fbfbfb,
    'grey-99': #fcfcfc,
    'sky': #93c1e7,
    'blue': #1b75dd,
    'midnight': #1558a8,
    'arctic': #03aaff,
    'blue-mist': #edf5ff,
    'rain': #9abcc7,
    'aqua': #7ec2b9,
    'salmon': #e2a3a4,
    'deep-red': #c34746,
    'coral': #dc5b5b,
    'peach': #ff9191,
    'golden': #d08f22,
    'yellow': #ebb70e,
    'olive': #5f8153,
    'grass': #98ca96,
    'purple': #817baf,
    'lavender': #d5c8da,
    'sand': #fffcf3,
    'marshmallow': #fff2f2,
    'almond': #c29e88,
    'caramel': #e4b9a0,
    'waterfall': #dbebff
);

[ui-theme],
:root:not([data-uinew]) {
    --default-color-black-off: #{map.get($ui-color, 'black-off')};
    --default-color-black-off-semi: #{map.get($ui-color, 'black-off-semi')};
    --default-color-black-off-dark: #{map.get($ui-color, 'black-off-dark')};
    --default-color-black: #{map.get($ui-color, 'black')};
    --default-color-blue-dark: #{map.get($ui-color, 'blue-dark')};
    --default-color-blue-light: #{map.get($ui-color, 'blue-light')};
    --default-color-blue-superlight: #{map.get($ui-color, 'blue-superlight')};
    --default-color-blue: #{map.get($ui-color, 'blue')};
    --default-color-blue-semilight: #{map.get($ui-color, 'blue-semilight')};
    --default-color-gold-light: #{map.get($ui-color, 'gold-light')};
    --default-color-gold: #{map.get($ui-color, 'gold')};
    --default-color-green: #{map.get($ui-color, 'green')};
    --default-color-grey: #{map.get($ui-color, 'grey')};
    --default-color-grey-dark: #{map.get($ui-color, 'grey-dark')};
    --default-color-grey-semidark: #{map.get($ui-color, 'grey-semidark')};
    --default-color-grey-semilight: #{map.get($ui-color, 'grey-semilight')};
    --default-color-grey-light: #{map.get($ui-color, 'grey-light')};
    --default-color-orange: #{map.get($ui-color, 'orange')};
    --default-color-red-light: #{map.get($ui-color, 'red-light')};
    --default-color-red: #{map.get($ui-color, 'red')};
    --default-color-white-off: #{map.get($ui-color, 'white-off')};
    --default-color-white-off-light: #{map.get($ui-color, 'white-off-light')};
    --default-color-white: #{map.get($ui-color, 'white')};
    --default-color-yellow: #{map.get($ui-color, 'yellow')};
    --ui-static-color-white: #{map.get($ui-color, 'white')};
    --ui-static-color-black: #{map.get($ui-color, 'black')};
    --ui-static-color-black-off-semi: #{map.get($ui-color, 'black-off-semi')};
    --ui-static-color-grey-6: #{map.get($ui-color, 'grey-6')};
    --ui-static-color-grey-12: #{map.get($ui-color, 'grey-12')};
    --ui-static-color-grey-21: #{map.get($ui-color, 'grey-21')};
    --ui-static-color-grey-31: #{map.get($ui-color, 'grey-31')};
    --ui-static-color-grey-61: #{map.get($ui-color, 'grey-61')};
    --ui-static-color-grey-71: #{map.get($ui-color, 'grey-71')};
    --ui-static-color-grey-84: #{map.get($ui-color, 'grey-84')};
    --ui-static-color-grey-86: #{map.get($ui-color, 'grey-86')};
    --ui-static-color-grey-89: #{map.get($ui-color, 'grey-89')};
    --ui-static-color-grey-92: #{map.get($ui-color, 'grey-92')};
    --ui-static-color-grey-93: #{map.get($ui-color, 'grey-93')};
    --ui-static-color-grey-94: #{map.get($ui-color, 'grey-94')};
    --ui-static-color-grey-95: #{map.get($ui-color, 'grey-95')};
    --ui-static-color-grey-96: #{map.get($ui-color, 'grey-96')};
    --ui-static-color-grey-97: #{map.get($ui-color, 'grey-97')};
    --ui-static-color-grey-98: #{map.get($ui-color, 'grey-98')};
    --ui-static-color-grey-99: #{map.get($ui-color, 'grey-99')};
    --ui-static-color-sky: #{map.get($ui-color, 'sky')};
    --ui-static-color-blue: #{map.get($ui-color, 'blue')};
    --ui-static-color-midnight: #{map.get($ui-color, 'midnight')};
    --ui-static-color-arctic: #{map.get($ui-color, 'arctic')};
    --ui-static-color-blue-mist: #{map.get($ui-color, 'blue-mist')};
    --ui-static-color-rain: #{map.get($ui-color, 'rain')};
    --ui-static-color-aqua: #{map.get($ui-color, 'aqua')};
    --ui-static-color-salmon: #{map.get($ui-color, 'salmon')};
    --ui-static-color-deep-red: #{map.get($ui-color, 'deep-red')};
    --ui-static-color-coral: #{map.get($ui-color, 'coral')};
    --ui-static-color-peach: #{map.get($ui-color, 'peach')};
    --ui-static-color-golden: #{map.get($ui-color, 'golden')};
    --ui-static-color-yellow: #{map.get($ui-color, 'yellow')};
    --ui-static-color-olive: #{map.get($ui-color, 'olive')};
    --ui-static-color-grass: #{map.get($ui-color, 'grass')};
    --ui-static-color-purple: #{map.get($ui-color, 'purple')};
    --ui-static-color-lavender: #{map.get($ui-color, 'lavender')};
    --ui-static-color-sand: #{map.get($ui-color, 'sand')};
    --ui-static-color-marshmallow: #{map.get($ui-color, 'marshmallow')};
    --ui-static-color-almond: #{map.get($ui-color, 'almond')};
    --ui-static-color-caramel: #{map.get($ui-color, 'caramel')};
    --ui-static-color-waterfall: #{map.get($ui-color, 'waterfall')};
}
