:root[data-uinew] {
	--brand-vivid-blue-1000: rgba(0, 108, 253, 100%);
	--neutral-000: rgba(255, 255, 255, 100%);
	--neutral-1000: rgba(0, 0, 0, 100%);
	--neutral-300: rgba(196, 196, 196, 100%);
	--neutral-400: rgba(158, 158, 158, 100%);
	--neutral-900: rgba(1, 1, 1, 100%);
	--neutral-500: rgba(102, 102, 102, 100%);
	--neutral-100: rgba(237, 237, 237, 100%);
	--font-family-primary: roboto flex;
	--neutral-600: rgba(64, 64, 64, 100%);
	--neutral-800: rgba(33, 33, 33, 100%);
	--font-numeric-275: 11px;
	--font-numeric-400: 16px;
	--font-numeric-500: 20px;
	--font-numeric-600: 24px;
	--font-numeric-800: 32px;
	--font-numeric-350: 14px;
	--font-numeric-250: 10px;
	--font-size-250: var(--font-numeric-250);
	--font-size-275: var(--font-numeric-275);
	--font-size-350: var(--font-numeric-350);
	--font-size-400: var(--font-numeric-400);
	--font-line-height-350: var(--font-numeric-350);
	--font-numeric-10000: 400px;
	--font-numeric-12500: 500px;
	--font-numeric-15000: 600px;
	--font-numeric-1000: 40px;
	--font-size-500: var(--font-numeric-500);
	--font-size-600: var(--font-numeric-600);
	--font-size-800: var(--font-numeric-800);
	--font-line-height-400: var(--font-numeric-400);
	--font-line-height-500: var(--font-numeric-500);
	--font-line-height-600: var(--font-numeric-600);
	--font-line-height-800: var(--font-numeric-800);
	--font-line-height-1000: var(--font-numeric-1000);
	--font-font-weight-10000: var(--font-numeric-10000);
	--font-font-weight-12500: var(--font-numeric-12500);
	--font-font-weight-15000: var(--font-numeric-15000);
	--neutral-1000-opacity-20: rgba(0, 0, 0, 20%);
	--numeric-050: 2px;
	--numeric-100: 4px;
	--numeric-200: 8px;
	--numeric-025: 1px;
	--numeric-0125: 0.5px;
	--numeric-300: 12px;
	--numeric-400: 16px;
	--numeric-500: 20px;
	--numeric-600: 24px;
	--numeric-700: 28px;
	--numeric-800: 32px;
	--numeric-1000: 40px;
	--numeric-1200: 48px;
	--numeric-1600: 64px;
	--border-radius-lg: var(--numeric-300);
	--border-width-sm: var(--numeric-025);
	--space-050: var(--numeric-050);
	--border-radius-sm: var(--numeric-100);
	--border-radius-md: var(--numeric-200);
	--border-radius-xl: var(--numeric-400);
	--border-width-xs: var(--numeric-0125);
	--space-100: var(--numeric-100);
	--space-200: var(--numeric-200);
	--space-300: var(--numeric-300);
	--space-400: var(--numeric-400);
	--space-500: var(--numeric-500);
	--space-600: var(--numeric-600);
	--space-700: var(--numeric-700);
	--space-800: var(--numeric-800);
	--space-1000: var(--numeric-1000);
	--space-1200: var(--numeric-1200);
	--numeric-2800: 112px;
	--border-radius-full: var(--numeric-2800);
	--border-radius-xs: var(--numeric-050);
	--system-red-1000: rgba(225, 113, 129, 100%);
	--system-green-1000: rgba(92, 191, 166, 100%);
	--system-yellow-1000: rgba(247, 204, 126, 100%);
	--system-blue-1000: rgba(0, 143, 254, 100%);
	--font-line-height-275: var(--font-numeric-275);
	--neutral-050: rgba(245, 245, 245, 100%);
	--font-numeric-700: 28px;
	--font-line-height-700: var(--font-numeric-700);
	--system-purple-1000: rgba(202, 105, 240, 100%);
	--neutral-700: rgba(51, 51, 51, 100%);
	--neutral-200: rgba(226, 226, 226, 100%);
	--system-blue-200: rgba(204, 233, 255, 100%);
	--system-green-350: rgba(198, 233, 224, 100%);
	--system-red-400: rgba(243, 198, 205, 100%);
	--system-yellow-350: rgba(252, 237, 210, 100%);
	--height-xs: var(--numeric-600);
	--height-sm: var(--numeric-800);
	--height-md: var(--numeric-1000);
	--brand-metal-90: rgba(244, 246, 248, 100%);
	--brand-metal-1000: rgba(132, 150, 179, 100%);
	--brand-metal-500: rgba(193, 203, 217, 100%);
	--brand-metal-140: rgba(238, 240, 244, 100%);
	--font-size-1000: var(--font-numeric-1000);
	--font-line-height-1200: var(--font-numeric-1200);
	--font-numeric-1200: 48px;
	--brand-vivid-blue-100: rgba(229, 240, 255, 100%);
	--brand-vivid-blue-800: rgba(51, 137, 253, 100%);
	--brand-metal-300: rgba(218, 223, 232, 100%);
	--system-red-550: rgba(239, 177, 186, 100%);
	--font-numeric-525: 21px;
	--font-line-height-525: var(--font-numeric-525);
	--brand-dark-grey-1000: rgba(50, 56, 70, 100%);
	--brand-dark-grey-800: rgba(91, 96, 107, 100%);
	--brand-metal-50: rgba(249, 250, 251, 100%);
	--system-dark-red-1000: rgba(214, 61, 84, 100%);
	--system-blue-350: rgba(166, 204, 254, 100%);
	--border-width-focus: var(--numeric-050);
	--system-dark-green-1000: rgba(50, 129, 109, 100%);


	[data-uitheme="light"], &[data-uitheme="light"] {
		--surface-neutral-subtlest: var(--neutral-000);
		--icon-primary: var(--neutral-900);
		--text-primary: var(--neutral-900);
		--text-secondary: var(--neutral-500);
		--text-disabled: var(--neutral-300);
		--icon-primary-inverted: var(--neutral-000);
		--text-primary-inverted: var(--neutral-000);
		--fill-disabled: var(--neutral-200);
		--border-disabled: var(--neutral-300);
		--icon-disabled: var(--neutral-300);
		--icon-secondary: var(--neutral-500);
		--surface-overlay: var(--neutral-1000-opacity-20);
		--fill-system-info-subtler: var(--system-blue-200);
		--border-system-danger-bold: var(--system-red-1000);
		--border-system-success-boldest: var(--system-green-1000);
		--icon-status-not-approved: var(--system-red-1000);
		--icon-status-approved: var(--system-green-1000);
		--icon-status-in-progress: var(--system-yellow-1000);
		--icon-status-for-review: var(--system-blue-1000);
		--icon-status-no-status: var(--neutral-400);
		--fill-system-recording: var(--system-red-1000);
		--surface-neutral-subtler: var(--neutral-050);
		--fill-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--text-negative-number: var(--system-dark-red-1000);
		--text-positive-number: var(--system-dark-green-1000);
		--text-brand: var(--brand-vivid-blue-1000);
		--icon-brand: var(--brand-vivid-blue-1000);
		--text-link: var(--brand-vivid-blue-1000);
		--text-link-pressed: var(--system-blue-1000);
		--border-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--border-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-subtlest: var(--neutral-000);
		--text-link-hover: var(--system-blue-1000);
		--fill-neutral-boldest: var(--neutral-600);
		--fill-brand-secondary-subtler: var(--brand-metal-90);
		--fill-brand-secondary-subtle: var(--brand-metal-140);
		--fill-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-bold: var(--neutral-400);
		--fill-brand-primary-subtlest: var(--brand-vivid-blue-100);
		--fill-system-danger-subtler: var(--system-red-400);
		--fill-system-success-subtler: var(--system-green-350);
		--fill-system-warning-subtler: var(--system-yellow-350);
		--border-system-info-boldest: var(--system-blue-1000);
		--border-system-warning-boldest: var(--system-yellow-1000);
		--surface-transparent: rgba(255, 255, 255, 0%);
		--fill-transparent: rgba(255, 255, 255, 0%);
		--border-brand-secondary-boldest: var(--brand-metal-1000);
		--border-brand-secondary-bold: var(--brand-metal-500);
		--fill-brand-secondary-neutral: var(--brand-metal-300);
		--border-neutral-bolder: var(--neutral-600);
		--border-neutral-subtle: var(--neutral-100);
		--fill-system-danger-subtle: var(--system-red-550);
		--fill-brand-secondary-subtlest: var(--brand-metal-50);
		--fill-system-danger-bold: var(--system-dark-red-1000);
		--text-error: var(--system-dark-red-1000);
		--icon-error: var(--system-dark-red-1000);
		--border-system-danger-boldest: var(--system-dark-red-1000);
		--border-system-focus: var(--system-blue-350);
		--fill-neutral-subtle: var(--neutral-100);
		--border-transparent: rgba(255, 255, 255, 0%);
	}

	[data-uitheme="dark"], &[data-uitheme="dark"] {
		--surface-neutral-subtlest: var(--neutral-800);
		--icon-primary: var(--neutral-000);
		--text-primary: var(--neutral-000);
		--text-secondary: var(--neutral-200);
		--text-disabled: var(--neutral-500);
		--icon-primary-inverted: var(--neutral-900);
		--text-primary-inverted: var(--neutral-900);
		--fill-disabled: var(--neutral-500);
		--border-disabled: var(--neutral-500);
		--icon-disabled: var(--neutral-500);
		--icon-secondary: var(--neutral-200);
		--surface-overlay: var(--neutral-1000-opacity-20);
		--fill-system-info-subtler: var(--system-blue-1000);
		--border-system-danger-bold: var(--system-red-550);
		--border-system-success-boldest: var(--system-green-350);
		--icon-status-not-approved: var(--system-red-1000);
		--icon-status-approved: var(--system-green-1000);
		--icon-status-in-progress: var(--system-yellow-1000);
		--icon-status-for-review: var(--system-blue-1000);
		--icon-status-no-status: var(--neutral-300);
		--fill-system-recording: var(--system-red-1000);
		--surface-neutral-subtler: var(--neutral-600);
		--fill-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--text-negative-number: var(--system-dark-red-1000);
		--text-positive-number: var(--system-green-1000);
		--text-brand: var(--brand-vivid-blue-1000);
		--icon-brand: var(--brand-vivid-blue-1000);
		--text-link: var(--brand-vivid-blue-1000);
		--text-link-pressed: var(--system-blue-1000);
		--border-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--border-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-subtlest: var(--neutral-600);
		--text-link-hover: var(--system-blue-1000);
		--fill-neutral-boldest: var(--neutral-000);
		--fill-brand-secondary-subtler: var(--brand-dark-grey-1000);
		--fill-brand-secondary-subtle: var(--brand-dark-grey-800);
		--fill-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-bold: var(--neutral-200);
		--fill-brand-primary-subtlest: var(--brand-vivid-blue-100);
		--fill-system-danger-subtler: var(--system-red-1000);
		--fill-system-success-subtler: var(--system-green-1000);
		--fill-system-warning-subtler: var(--system-yellow-1000);
		--border-system-info-boldest: var(--system-blue-200);
		--border-system-warning-boldest: var(--system-yellow-350);
		--surface-transparent: rgba(0, 0, 0, 0%);
		--fill-transparent: rgba(255, 255, 255, 0%);
		--border-brand-secondary-boldest: var(--brand-dark-grey-1000);
		--border-brand-secondary-bold: var(--brand-dark-grey-800);
		--fill-brand-secondary-neutral: var(--brand-metal-300);
		--border-neutral-bolder: var(--neutral-100);
		--border-neutral-subtle: var(--neutral-600);
		--fill-system-danger-subtle: var(--system-red-550);
		--fill-brand-secondary-subtlest: var(--brand-dark-grey-1000);
		--fill-system-danger-bold: var(--system-dark-red-1000);
		--text-error: var(--system-dark-red-1000);
		--icon-error: var(--system-dark-red-1000);
		--border-system-danger-boldest: var(--system-red-400);
		--border-system-focus: var(--system-blue-350);
		--fill-neutral-subtle: var(--neutral-400);
		--border-transparent: rgba(255, 255, 255, 0%);
	}

	[data-uienv="platform"], &[data-uienv="platform"] {
		--button-fill-primary: var(--fill-brand-primary-boldest);
		--button-fill-secondary: var(--fill-brand-secondary-subtler);
		--button-fill-primary-disabled: var(--fill-disabled);
		--button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--forms-border-primary-enabled: var(--border-brand-secondary-boldest);
		--forms-border-primary-disabled: var(--border-disabled);
		--forms-border-primary-selected: var(--border-brand-primary-boldest);
		--forms-border-primary-hover: var(--border-brand-primary-boldest-hover);
		--forms-border-primary-filled: var(--border-neutral-bolder);
		--forms-border-primary-error: var(--border-system-danger-boldest);
		--forms-fill-primary-enabled: var(--fill-neutral-subtlest);
		--table-cell-padding: var(--numeric-400);
		--table-fill-hover: var(--fill-brand-secondary-subtler);
		--table-fill-even: var(--fill-neutral-subtlest);
		--menu-item-fill: var(--fill-neutral-subtlest);
		--menu-item-fill-hover: var(--fill-brand-secondary-subtle);
		--menu-item-fill-selected: var(--fill-brand-primary-boldest);
		--slider-fill-active: var(--fill-brand-primary-boldest);
		--slider-fill: var(--fill-neutral-bold);
		--toggle-fill-active: var(--fill-brand-primary-boldest);
		--toggle-fill: var(--fill-neutral-bold);
		--snackbar-fill-info: var(--fill-system-info-subtler);
		--snackbar-fill-warning: var(--fill-system-warning-subtler);
		--snackbar-fill-success: var(--fill-system-success-subtler);
		--snackbar-fill-error: var(--fill-system-danger-subtler);
		--snackbar-border-info: var(--border-system-info-boldest);
		--snackbar-border-success: var(--border-system-success-boldest);
		--snackbar-border-warning: var(--border-system-warning-boldest);
		--snackbar-border-error: var(--border-system-danger-bold);
		--button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-fill-default: var(--fill-neutral-subtlest);
		--list-item-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-fill-selected: var(--fill-brand-secondary-subtle);
		--table-fill-odd: var(--fill-brand-secondary-subtlest);
		--button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--tabs-fill: var(--fill-brand-secondary-subtler);
		--tabs-fill-primary-selected: var(--fill-brand-primary-boldest);
		--tabs-fill-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill: var(--fill-transparent);
		--floating-button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--floating-button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-border: var(--border-brand-secondary-bold);
		--button-fill-primary-destructive: var(--system-dark-red-1000);
		--floating-button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-fill-divider: var(--border-brand-secondary-bold);
		--calendar-item-fill: var(--fill-neutral-subtlest);
		--calendar-item-fill-hover: var(--fill-brand-secondary-subtle);
		--calendar-item-fill-selected: var(--fill-brand-primary-boldest);
		--calendar-item-fill-selected-span: var(--fill-brand-primary-subtlest);
		--button-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--list-item-fill-pressed: var(--fill-brand-secondary-neutral);
		--table-fill-pressed: var(--fill-brand-secondary-neutral);
		--tabs-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-fill-destructive-hover: var(--system-dark-red-1000);
		--button-border-secondary-destructive: var(--system-red-1000);
		--button-fill-tertiary: var(--fill-neutral-subtlest);
		--button-fill-tertiary-hover: var(--fill-brand-secondary-subtler);
		--button-fill-tertiary-pressed: var(--fill-brand-secondary-neutral);
		--button-fill-tertiary-selected: var(--fill-brand-secondary-subtler);
		--toggle-fill-disabled: var(--fill-disabled);
		--button-fill-primary-destructive-hover: var(--fill-neutral-subtlest);
		--button-text-primary: var(--text-primary-inverted);
		--button-text-primary-inverted: var(--text-primary);
		--button-text-tetriary: var(--text-primary);
		--button-text-primary-destructive: var(--text-primary-inverted);
		--button-text-primary-destructive-inverted: var(--text-error);
		--button-text-brand: var(--text-brand);
		--button-text-disabled: var(--text-disabled);
		--button-icon-primary: var(--icon-primary-inverted);
		--button-icon-primary-inverted: var(--icon-primary);
		--button-icon-brand: var(--icon-brand);
		--button-icon-primary-destructive: var(--icon-primary-inverted);
		--button-icon-disabled: var(--icon-disabled);
		--button-text-secondary: var(--text-secondary);
		--button-icon-secondary: var(--icon-secondary);
		--button-icon-primary-destructive-inverted: var(--icon-error);
		--list-item-border-default: var(--border-neutral-subtle);
		--forms-text-input-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-hover: var(--text-primary);
		--forms-text-input-primary-disabled: var(--text-disabled);
		--forms-text-input-primary-selected: var(--text-primary);
		--forms-text-help-primary-hover: var(--text-primary);
		--forms-text-help-primary-disabled: var(--text-disabled);
		--forms-text-help-primary-selected: var(--text-primary);
		--forms-text-help-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-error: var(--text-primary);
		--forms-text-help-primary-error: var(--text-error);
		--forms-text-input-primary-filled: var(--text-primary);
		--forms-text-help-primary-filled: var(--text-primary);
		--forms-icon-primary-enabled: var(--icon-secondary);
		--forms-icon-primary-hover: var(--icon-primary);
		--forms-icon-primary-disabled: var(--icon-disabled);
		--forms-icon-primary-selected: var(--icon-primary);
		--forms-icon-primary-filled: var(--icon-primary);
		--forms-icon-primary-error: var(--icon-primary);
		--chips-fill-default: var(--fill-neutral-subtlest);
		--chips-fill-selected: var(--fill-brand-primary-boldest);
		--chips-border-default: var(--border-brand-secondary-bold);
		--chips-text-default: var(--text-primary);
		--chips-text-selected: var(--text-primary-inverted);
		--chips-icon-selected: var(--icon-primary-inverted);
		--chips-icon-default: var(--icon-primary);
		--forms-fill-primary-hover: var(--fill-neutral-subtlest);
		--forms-fill-primary-selected: var(--fill-neutral-subtlest);
		--forms-fill-primary-filled: var(--fill-neutral-subtlest);
		--forms-fill-primary-disabled: var(--fill-neutral-subtlest);
		--forms-fill-primary-error: var(--fill-neutral-subtlest);
		--forms-text-suffix-secondary-enabled: var(--text-secondary);
		--forms-text-suffix-secondary-hover: var(--text-secondary);
		--forms-text-suffix-secondary-selected: var(--text-secondary);
		--forms-text-suffix-secondary-filled: var(--text-secondary);
		--forms-text-suffix-secondary-disabled: var(--text-disabled);
		--forms-text-suffix-secondary-error: var(--text-secondary);
		--forms-border-secondary-error: var(--border-system-danger-boldest);
		--forms-border-secondary-disabled: var(--border-disabled);
		--forms-border-secondary-selected: var(--border-brand-primary-boldest);
		--forms-border-secondary-hover: var(--border-brand-secondary-boldest);
		--forms-border-secondary-filled: var(--border-transparent);
		--forms-border-secondary-enabled: var(--border-brand-secondary-boldest);
		--forms-fill-secondary-error: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-enabled: var(--fill-brand-secondary-subtler);
		--forms-icon-secondary-error: var(--icon-primary);
		--forms-icon-secondary-hover: var(--icon-primary);
		--forms-icon-secondary-disabled: var(--icon-disabled);
		--forms-icon-secondary-filled: var(--icon-primary);
		--forms-icon-secondary-selected: var(--icon-primary);
		--forms-icon-secondary-enabled: var(--icon-secondary);
		--forms-text-input-secondary-error: var(--text-primary);
		--forms-text-input-secondary-enabled: var(--text-secondary);
		--forms-text-input-secondary-selected: var(--text-primary);
		--forms-text-input-secondary-disabled: var(--text-disabled);
		--forms-text-input-secondary-filled: var(--text-primary);
		--forms-text-input-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-error: var(--text-error);
		--forms-text-help-secondary-selected: var(--text-primary);
		--forms-text-help-secondary-disabled: var(--text-disabled);
		--forms-text-help-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-filled: var(--text-primary);
		--forms-text-help-secondary-enabled: var(--text-secondary);
	}

	[data-uienv="studio"], &[data-uienv="studio"] {
		--button-fill-primary: var(--fill-brand-primary-boldest);
		--button-fill-secondary: var(--fill-brand-secondary-subtle);
		--button-fill-primary-disabled: var(--fill-disabled);
		--button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--forms-border-primary-enabled: var(--border-brand-secondary-boldest);
		--forms-border-primary-disabled: var(--border-disabled);
		--forms-border-primary-selected: var(--border-brand-primary-boldest);
		--forms-border-primary-hover: var(--border-brand-secondary-boldest);
		--forms-border-primary-filled: rgba(255, 255, 255, 0%);
		--forms-border-primary-error: var(--border-system-danger-boldest);
		--forms-fill-primary-enabled: var(--fill-brand-secondary-subtler);
		--table-cell-padding: var(--numeric-400);
		--table-fill-hover: var(--fill-brand-secondary-subtler);
		--table-fill-even: var(--fill-neutral-subtlest);
		--menu-item-fill: var(--fill-neutral-subtlest);
		--menu-item-fill-hover: var(--fill-brand-secondary-subtle);
		--menu-item-fill-selected: var(--fill-brand-primary-boldest);
		--slider-fill-active: var(--fill-neutral-boldest);
		--slider-fill: var(--fill-neutral-bold);
		--toggle-fill-active: var(--fill-neutral-boldest);
		--toggle-fill: var(--fill-neutral-bold);
		--snackbar-fill-info: var(--fill-system-info-subtler);
		--snackbar-fill-warning: var(--fill-system-warning-subtler);
		--snackbar-fill-success: var(--fill-system-success-subtler);
		--snackbar-fill-error: var(--fill-system-danger-subtler);
		--snackbar-border-info: var(--border-system-info-boldest);
		--snackbar-border-success: var(--border-system-success-boldest);
		--snackbar-border-warning: var(--border-system-warning-boldest);
		--snackbar-border-error: var(--border-system-danger-bold);
		--button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--button-fill-secondary-pressed: var(--fill-brand-secondary-subtle);
		--list-item-fill-default: var(--fill-neutral-subtlest);
		--list-item-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-fill-selected: var(--fill-brand-secondary-subtle);
		--table-fill-odd: var(--fill-brand-secondary-subtlest);
		--button-fill-secondary-hover: var(--fill-brand-secondary-neutral);
		--tabs-fill: var(--fill-brand-secondary-subtler);
		--tabs-fill-primary-selected: var(--fill-brand-primary-boldest);
		--tabs-fill-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill: var(--fill-transparent);
		--floating-button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--floating-button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-border: var(--border-brand-secondary-bold);
		--button-fill-primary-destructive: var(--system-dark-red-1000);
		--floating-button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-fill-divider: var(--border-brand-secondary-bold);
		--calendar-item-fill: var(--fill-neutral-subtlest);
		--calendar-item-fill-hover: var(--fill-brand-secondary-subtler);
		--calendar-item-fill-selected: var(--fill-brand-primary-boldest);
		--calendar-item-fill-selected-span: var(--fill-brand-primary-subtlest);
		--button-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--list-item-fill-pressed: var(--fill-brand-secondary-neutral);
		--table-fill-pressed: var(--fill-brand-secondary-neutral);
		--tabs-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-fill-destructive-hover: var(--system-dark-red-1000);
		--button-border-secondary-destructive: var(--system-dark-red-1000);
		--button-fill-tertiary: var(--fill-neutral-subtlest);
		--button-fill-tertiary-hover: var(--fill-brand-secondary-subtler);
		--button-fill-tertiary-pressed: var(--fill-brand-secondary-neutral);
		--button-fill-tertiary-selected: var(--fill-brand-secondary-subtler);
		--toggle-fill-disabled: var(--fill-disabled);
		--button-fill-primary-destructive-hover: var(--fill-neutral-subtlest);
		--button-text-primary: var(--text-primary-inverted);
		--button-text-primary-inverted: var(--text-primary);
		--button-text-tetriary: var(--text-primary);
		--button-text-primary-destructive: var(--text-primary-inverted);
		--button-text-primary-destructive-inverted: var(--text-error);
		--button-text-brand: var(--text-brand);
		--button-text-disabled: var(--text-disabled);
		--button-icon-primary: var(--icon-primary-inverted);
		--button-icon-primary-inverted: var(--icon-primary);
		--button-icon-brand: var(--icon-brand);
		--button-icon-primary-destructive: var(--icon-primary-inverted);
		--button-icon-disabled: var(--icon-disabled);
		--button-text-secondary: var(--text-secondary);
		--button-icon-secondary: var(--icon-secondary);
		--button-icon-primary-destructive-inverted: var(--icon-error);
		--list-item-border-default: var(--border-neutral-subtle);
		--forms-text-input-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-hover: var(--text-primary);
		--forms-text-input-primary-disabled: var(--text-disabled);
		--forms-text-input-primary-selected: var(--text-primary);
		--forms-text-help-primary-hover: var(--text-primary);
		--forms-text-help-primary-disabled: var(--text-disabled);
		--forms-text-help-primary-selected: var(--text-primary);
		--forms-text-help-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-error: var(--text-primary);
		--forms-text-help-primary-error: var(--text-error);
		--forms-text-input-primary-filled: var(--text-primary);
		--forms-text-help-primary-filled: var(--text-primary);
		--forms-icon-primary-enabled: var(--icon-secondary);
		--forms-icon-primary-hover: var(--icon-primary);
		--forms-icon-primary-disabled: var(--icon-disabled);
		--forms-icon-primary-selected: var(--icon-primary);
		--forms-icon-primary-filled: var(--icon-primary);
		--forms-icon-primary-error: var(--icon-primary);
		--chips-fill-default: var(--fill-brand-secondary-subtler);
		--chips-fill-selected: var(--fill-neutral-boldest);
		--chips-border-default: var(--border-brand-secondary-bold);
		--chips-text-default: var(--text-primary);
		--chips-text-selected: var(--text-primary-inverted);
		--chips-icon-selected: var(--icon-primary-inverted);
		--chips-icon-default: var(--icon-primary);
		--forms-fill-primary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-error: var(--fill-brand-secondary-subtler);
		--forms-text-suffix-secondary-enabled: var(--text-secondary);
		--forms-text-suffix-secondary-hover: var(--text-secondary);
		--forms-text-suffix-secondary-selected: var(--text-secondary);
		--forms-text-suffix-secondary-filled: var(--text-secondary);
		--forms-text-suffix-secondary-disabled: var(--text-disabled);
		--forms-text-suffix-secondary-error: var(--text-secondary);
		--forms-border-secondary-error: var(--border-system-danger-boldest);
		--forms-border-secondary-disabled: var(--border-disabled);
		--forms-border-secondary-selected: var(--border-brand-primary-boldest);
		--forms-border-secondary-hover: var(--border-brand-secondary-boldest);
		--forms-border-secondary-filled: rgba(255, 255, 255, 0%);
		--forms-border-secondary-enabled: var(--border-brand-secondary-boldest);
		--forms-fill-secondary-error: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-enabled: var(--fill-brand-secondary-subtler);
		--forms-icon-secondary-error: var(--icon-primary);
		--forms-icon-secondary-hover: var(--icon-primary);
		--forms-icon-secondary-disabled: var(--icon-disabled);
		--forms-icon-secondary-filled: var(--icon-primary);
		--forms-icon-secondary-selected: var(--icon-primary);
		--forms-icon-secondary-enabled: var(--icon-secondary);
		--forms-text-input-secondary-error: var(--text-primary);
		--forms-text-input-secondary-enabled: var(--text-secondary);
		--forms-text-input-secondary-selected: var(--text-primary);
		--forms-text-input-secondary-disabled: var(--text-disabled);
		--forms-text-input-secondary-filled: var(--text-primary);
		--forms-text-input-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-error: var(--text-error);
		--forms-text-help-secondary-selected: var(--text-primary);
		--forms-text-help-secondary-disabled: var(--text-disabled);
		--forms-text-help-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-filled: var(--text-primary);
		--forms-text-help-secondary-enabled: var(--text-secondary);
	}

	[data-variant="xs"], &[data-variant="xs"] {
		--button-space-padding-horisontal: var(--space-200);
		--button-radius: var(--border-radius-xs);
		--forms-radius: var(--border-radius-xs);
		--button-height: var(--height-xs);
		--button-space-gap: var(--space-200);
		--button-group-item-space-padding-horisontal: var(--numeric-100);
		--forms-height: var(--height-xs);
		--forms-menu-dropdown-search-list-item-space-padding-horisontal: var(--space-200);
		--forms-menu-dropdown-search-list-item-space-gap: var(--space-200);
		--forms-space-padding-horisontal: var(--space-100);
		--forms-space-gap: var(--space-050);
		--tabs-height: var(--height-xs);
		--tabs-space-padding-horisontal: 0px;
		--tabs-radius: var(--border-radius-xs);
		--table-height: 0px;
		--table-space-padding-horisontal: 0px;
		--snackbar-radius: 0px;
		--snackbar-space-padding-horisontal: 0px;
		--snackbar-space-gap: 0px;
		--snackbar-space-padding-vertical: 0px;
		--banner-radius: 0px;
		--banner-space-padding-horisontal: 0px;
		--banner-space-padding-vertical: 0px;
		--label-space-gap: var(--space-100);
		--button-space-padding-focused: var(--border-width-sm);
	}

	[data-variant="sm"], &[data-variant="sm"] {
		--button-space-padding-horisontal: var(--space-200);
		--button-radius: var(--border-radius-sm);
		--forms-radius: var(--border-radius-sm);
		--button-height: var(--height-sm);
		--button-space-gap: var(--space-100);
		--button-group-item-space-padding-horisontal: var(--numeric-100);
		--forms-height: var(--height-sm);
		--forms-menu-dropdown-search-list-item-space-padding-horisontal: var(--space-200);
		--forms-menu-dropdown-search-list-item-space-gap: var(--space-100);
		--forms-space-padding-horisontal: var(--space-200);
		--forms-space-gap: var(--space-200);
		--tabs-height: var(--height-sm);
		--tabs-space-padding-horisontal: var(--space-300);
		--tabs-radius: var(--border-radius-sm);
		--table-height: 0px;
		--table-space-padding-horisontal: 0px;
		--snackbar-radius: 0px;
		--snackbar-space-padding-horisontal: 0px;
		--snackbar-space-gap: 0px;
		--snackbar-space-padding-vertical: 0px;
		--banner-radius: var(--border-radius-md);
		--banner-space-padding-horisontal: var(--space-200);
		--banner-space-padding-vertical: var(--space-200);
		--label-space-gap: var(--space-100);
		--button-space-padding-focused: var(--border-width-sm);
	}

	[data-variant="md"], &[data-variant="md"] {
		--button-space-padding-horisontal: var(--space-400);
		--button-radius: var(--border-radius-md);
		--forms-radius: var(--border-radius-sm);
		--button-height: var(--height-md);
		--button-space-gap: var(--space-200);
		--button-group-item-space-padding-horisontal: var(--numeric-100);
		--forms-height: var(--height-md);
		--forms-menu-dropdown-search-list-item-space-padding-horisontal: var(--space-300);
		--forms-menu-dropdown-search-list-item-space-gap: var(--space-200);
		--forms-space-padding-horisontal: var(--space-300);
		--forms-space-gap: var(--space-200);
		--tabs-height: var(--height-md);
		--tabs-space-padding-horisontal: var(--space-400);
		--tabs-radius: var(--border-radius-md);
		--table-height: var(--height-md);
		--table-space-padding-horisontal: var(--space-400);
		--snackbar-radius: var(--border-radius-md);
		--snackbar-space-padding-horisontal: var(--space-300);
		--snackbar-space-gap: var(--space-200);
		--snackbar-space-padding-vertical: var(--space-200);
		--banner-radius: 0px;
		--banner-space-padding-horisontal: 0px;
		--banner-space-padding-vertical: 0px;
		--label-space-gap: var(--space-200);
		--button-space-padding-focused: var(--border-width-sm);
	}
}